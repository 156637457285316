<!-- 已完成订单 -->
<template>
	<div :class="themeClass">
		<div class="allOrder-box margin-t-20">
			<div class="all-title font-size18 font-color-333 font-weight700">
				已完成订单
			</div>
			<div class="top flex-row-align-center">
				<div class="choose-date" style="width: 316px;display: flex;align-items: center;">
          <!-- 新时间组件 -->
            <Timequery @change="getTimeall"/>
          <!-- 新时间组件 -->
				</div>
				<div class="search-box">
					<el-input prefix-icon="el-icon-search" v-model.trim="searchText" placeholder="订单号/商品"> </el-input>
				</div>
				<div class="pointer search-btn margin-l-10 text-center" @click="queryListData">搜索</div>
			</div>
			<div class="content">
				<div class="nav margin-t-10 flex-row">
					<span>订单信息</span>
					<span>数量</span>
					<span>实付款</span>
					<span>状态</span>
					<span>操作</span>
				</div>
				<!-- 支付，跳转等方法在组件中 -->
				<order-item v-model="list" @queryList="queryListData"></order-item>
			</div>
			<div class="pagination">
				<pagination :currentPage="currentPage" :pagesize="pagesize" :total="total" @handleCurrentChange="handleCurrentChange"></pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex";
  import Timequery from "@/components/Timequery.vue"
	import orderItem from "../../../components/business/orderItem";
	import pagination from "../../../components/pagination/pagination";
	export default {
		name: "",
		components: {
			orderItem,
			pagination,
      Timequery
		},
		props: {},
		data() {
			return {
				activeName: "threemonth",
				searchDate: ['', ''],
				searchText: "",
				list: [],
				// 当前页数
				currentPage: 1,
				// 每页条数
				pagesize: 5,
				// 总条数
				total: 0,
			};
		},
		computed: {
			...mapGetters(["getThemeName"]),
			themeClass() {
				return `theme-${this.getThemeName}`;
			},
		},
		mounted() {
			// const start = new Date();
			// const end = new Date();
			// this.searchDate[0] = new Date(start.setTime(start.getTime() - 3600 * 1000 * 24 * 90))
			// this.searchDate[1] = end
			// this.queryListData();
		},
		methods: {
      getTimeall (val) {
        console.log(val)
        this.searchDate[0] = val[0]
			  this.searchDate[1] = val[1]
        this.queryListData();
      },
			// 快捷切换时间
			// handleClick(tab, event) {
			// 	if (this.searchDate == null) {
			// 		this.searchDate = ['', '']
			// 	}
			// 	const start = new Date();
			// 	const end = new Date();
			// 	switch (this.activeName) {
			// 		case "all":
			// 			this.searchDate[0] = ''
			// 			this.searchDate[1] = ''
			// 			break;
			// 		case "sevendays":
			// 			this.searchDate[0] = new Date(start.setTime(start.getTime() - 3600 * 1000 * 24 * 7))
			// 			this.searchDate[1] = end
			// 			break;
			// 		case "threemonth":
			// 			this.searchDate[0] = new Date(start.setTime(start.getTime() - 3600 * 1000 * 24 * 90))
			// 			this.searchDate[1] = end
			// 			break;
			// 		case "oneyear":
			// 			this.searchDate[0] = new Date(start.setTime(start.getTime() - 3600 * 1000 * 24 * 365))
			// 			this.searchDate[1] = end
			// 			break;
			// 	}
			// 	this.currentPage = 1;
			// 	this.queryListData();
			// },
			// 当前页 改变时会触发
			handleCurrentChange(Current) {
				this.currentPage = Current;
				this.queryListData();
			},
			queryListData() {
				if (this.searchDate == null) {
					this.searchDate = ['', '']
				}
				this.ApiRequestPostNOMess('api/mall/ebsale/order/get-list-by-complete', {
					beginTime: this.searchDate[0] == '' ? this.searchDate[0] : this.getDate(this.searchDate[0]),
					endTime: this.searchDate[1] == '' ? this.searchDate[1] : this.getDate(this.searchDate[1]),
					filter: this.searchText,
					maxResultCount: this.pagesize,
					skipCount: (this.currentPage - 1) * this.pagesize
				}).then(res => {
					for (let item of res.obj.items) {
						item.fBeginDate = this.getTime(item.fBeginDate)
						item.fEndDate = this.getDate(item.fEndDate)
					}
					this.total = res.obj.totalCount;
					this.list = res.obj.items
				}, error => {});
			}
		}
	};
</script>

<style lang="scss" scoped>
	.color-theme {
		@include themify($themes) {
			color: themed("themes_color");
		}
	}

	.background-color-theme {
		@include themify($themes) {
			background-color: themed("themes_color");
			color: themed("text_color_white");
		}
	}

	.border-color-theme {
		@include themify($themes) {
			border: 1px solid themed("themes_color");
		}
	}

	/deep/ .el-tabs__item.is-active {
		color: #fff;

		@include themify($themes) {
			background-color: themed("themes_color");
		}
	}

	/deep/ .el-input__icon {
		line-height: 22px;
	}

	/deep/ .el-date-editor .el-range-separator {
		line-height: 24px;
	}

	/deep/ .el-input--prefix .el-input__inner {
		padding-left: 40px;
	}

	.allOrder-Box {
		height: 100%;
		background-color: #ff3040;
	}

	.all-title {
		height: 24px;
		line-height: 24px;
		margin-bottom: 4px;
	}

	.top {
		width: 100%;
		height: 32px;

		.el-date-editor.el-input,
		.el-date-editor.el-input__inner {
			width: 145px;
		}

		.search-box {
			width: 513px;
		}

		.search-btn {
			width: 79px;
			height: 32px;
			line-height: 32px;
			background: #f8f7fa;
			border-radius: 4px;
			border: 1px solid #f2f2f2;
		}
	}
	
	/deep/ .el-tabs__header {
		margin: 0;
	}

	/deep/ .el-tabs__item {
		padding: 0;
		width: 75px;
		height: 32px;
		line-height: 32px;
		text-align: center;
	}

	/deep/ .el-tabs__active-bar {
		display: none;
	}

	/deep/ .el-tabs__nav-wrap::after {
		background-color: transparent;
	}

	/deep/ .el-input__inner {
		height: 32px;
		line-height: 32px;
	}

	.title {
		width: 100%;
		height: 35px;
		background: #f8f9fe;
	}

	.content {
		.nav {
			width: 100%;
			height: 35px;
			line-height: 35px;
			background: #f8f9fe;

			span {
				display: block;
				text-align: center;

				&:nth-child(1) {
					width: 425px;
				}

				&:nth-child(n + 2) {
					width: 123px;
				}
			}
		}
	}

	.pagination {
		margin-top: 20px;
		float: right;
	}
</style>

